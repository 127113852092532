/* General Container Background */
.general-container {
  background: url("https://res.cloudinary.com/dqykhzxtl/image/upload/v1603264627/virtual-time-travel/Virtual-Time-Travel-Home_qzwgck.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
}

/* Waiting Room Container Background */
.waiting-room-container {
  background: url("https://res.cloudinary.com/dqykhzxtl/image/upload/v1604014220/virtual-time-travel/Virtual-Time-Travel-Waiting-Room_m3pxcp.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
}

#waiting-room-button {
  position: absolute;
  text-decoration: none;
  color: white;
  right: 300px;
  top: 350px;
}

/* Introduction Page */
.introduction{
  display: flex;
  flex-flow: row wrap;
  
  margin-top: 160px;
}

.intro-video, .intro-form {
  display: flex;
  width: 50%;
  flex-flow: row wrap;
  justify-content: center;
  padding: 20px;
  align-items: center;
}


/* Year Container Background*/
.year-container {
  background-size: cover;
}

/* Year Game Backgrounds */
.year-background { 
  position: relative;
  width: 1000px;
  height: 640px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Progress Bar */
.progress {
  margin: 5px auto;
  width: 1000px;
  height: 30px;
}

.progress-bar {
  font-weight: bold;
  color: black;
  font-size: 16px;
}

/* Game Icons */
.game-icons {
  position: absolute;
  display: flex;
  width: 30px;
  height: 30px;
  color: red;
  justify-content: center;
  align-items: center;
}

.game-icons:hover, .game-controls-info-icon:hover {
  cursor: pointer
}

.game-icons svg {
  width: 30px;
  height: 30px;
}

.solved {
  color: green;
}

/* Pop Over */
.popover {
  max-width: 500px;
}

.popover-images {
  width: 100%;
}


/* Game Controls Dashboard */
.game-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.game-controls-components {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.game-controls-single-component {
  width: 20%;
  padding: 10px;
  border: 2px solid black;
  border-radius: 5%;
  box-shadow: 5px 10px black;
}

.game-controls-info-icon {
  display: inline-block;
}

.game-controls-info-icon svg {
  vertical-align: top;
  font-size: 15px;
}



@media only screen and (max-width: 768px) {
  .intro-video, .intro-form {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    padding: 20px;
    align-items: center;
  }

  .game-controls-single-component {
    flex-basis: 100%;
  }
}

.compound-synthesizer {
  width: 35%;
}

/* Individual Puzzle Locks */
#puzzle-calculator-digits {
  left: 7.5%;
  bottom: 39%
}

#puzzle-elements-1 {
  left: 39%;
  bottom: 23%;
}

#puzzle-word-chart {
  right: 13%;
  bottom: 15%;
}

#puzzle-optometrist {
  left: 30%;
  top: 8%;
}

#puzzle-elements-2 {
  left: 16%;
  bottom: 22%;
}

#puzzle-skyscrapers {
  left: 46.5%;
  top: 39%;
}

#puzzle-clocks {
  left: 35.5%;
  top: 34%;
}

#puzzle-safe {
  right: 28%;
  bottom: 33%;
}

#locked-chest {
  right: 38%;
  bottom: 43%
}

#puzzle-caslight {
  right: 34%;
   top: 40%
}

.puzzle-slider-caslight {
  display: flex;
  flex-direction: column;
}

.slider-row {
  display: flex;
  flex-direction: row;
}

.slider-tile {
  display: flex;
  flex-direction: row;
  width: 100px;
  height: 100px;
  border: 1px solid black;
}

.slider-tile:hover {
  cursor: pointer;
}

.slider-tile > img {
  width: 100px;
  height: 100px;
}

/* Individual Clues */
.clocks-widget {
  display: flex;
  flex-direction: column;
}

.roll-button > Button {
  display: block;
  margin: 0 auto;
}

.die-rolls-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.die-roll {
  display: block;
  text-align: center;
  width: 60px;
  height: 60px;
}

.die-roll > img {
  width: 60px;
  height: 60px;
}

.clocks-widget-score {
  display: block;
  width: 60px;
  height: 60px;
  border: 2px solid black;
  margin: 0 auto;
  text-align: center;
  font-size: 32px;
}

.around-the-clock {
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
}

.message-from-uncle {
  display: block;
  /* margin: 0 auto; */
  width: 300px;
  height: 300px;
}

.siren-audio-controls {
  /* display: inline-block; */
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0px;
  background: rgba(255,255,255, 0.5)	
}

.siren-audio-controls > * {
  width: 50px;
  height: 50px;
}

.siren-audio-controls:hover {
  cursor: pointer;
  
}


/* General */
.text-center {
  text-align: center;
}

.text-white {
  color: white;
}

.btn {
  display: block;
  margin: 0 auto;
}